import { faHandHoldingUsd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Seo from "../components/seo"

const Inherit = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="不動産相続" description="株式会社ファーストの不動産相続" />
      <div className="container">
        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "gray",
            margin: "10px 0px",
          }}
        >
          <FontAwesomeIcon icon={faHandHoldingUsd} />
        </div>
        <h3 className="title is-4" style={{ color: "gray" }}>
          不動産相続
        </h3>
        <section className="section">
          <h4 className="title is-4">相続をトータルでお手伝い</h4>
          <p>遺産の相続で困っていませんか？</p>
          <p>
            相続手続きは、限られた時間の中で複数の作業を行わなければなりません。そのため、法定相続人同士で協力して、計画的に手続きを進めていくことが大切です。
          </p>
          <p className="block">
            当社は不動産相続のサポートがメインにはなりますが、司法書士などの専門のスタッフをご紹介できるため、相続全般をお手伝いできます。
          </p>

          <div className="columns is-centered">
            <div className="column is-half">
              <StaticImage
                src="../images/flows/flow-inherit.drawio.png"
                style={{ margin: "0 auto" }}
                placeholder="none"
                quality={90}
                alt=""
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>相続相談</span>
                  <ul>
                    <li>相続全般の疑問に応え、サポートプランを提案します</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>スタッフ招集・紹介</span>
                  <ul>
                    <li>必要に応じて、専門のスタッフをご紹介します</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>手続きサポート</span>
                  <ul>
                    <li>相続手続きをお手伝いします</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <p>
            相続には、「法定相続人の調査と決定」「相続財産の調査」「遺産分割協議書の作成」などの作業があり、専門知識が必要になります。
          </p>
          <p>
            限られた期間で作業を円滑に進めるためにも、相続については是非当社にご相談ください。
          </p>
          <Link
            to="/contact"
            className="button is-dark is-fullwidth my-4 is-size-5"
            style={{ height: "4em" }}
          >
            お問い合わせはこちら　＞
          </Link>
        </section>
      </div>
    </Layout>
  )
}

export default Inherit
